
import { defineComponent } from 'vue'
import NewPasswordSection from '@/components/sections/NewPasswordSection.vue'

export default defineComponent({
  name: 'NewPassword',
  components: {
    NewPasswordSection
  }
})
