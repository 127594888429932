
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import Logo from '@/assets/svg/logo.svg?inline'
import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'NewPasswordSection',
  data () {
    const schema = yup.object().shape({
      password: yup.string().required('Password is required!'),
      new_password: yup.string().required('Password is required!')
    })

    return {
      isModalOpen: false,
      schema,
      isCode: false,
      message: ''
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Logo,
    AlertBlock
  },
  methods: {
    sendNewPassword (data) {
      const code = JSON.parse(localStorage.getItem('code'))
      data.code = code
      this.$store.dispatch('auth/newPassword', data).then(() => {
        this.$router.push('/login')
        localStorage.removeItem('code')
      },
      (error) => {
        this.message = error.response.data.errors
      })
    }
  }
})
